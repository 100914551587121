
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.container {
    position: relative;
    color: #fff;
    padding: 50px 10px;
    &::before {
        content: "";
        left: 50%;
        top: 0;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        position: absolute;
        background-color: #244880;
        z-index: -10;
    }
}
.item {
    box-shadow: 0px 4px 12px 3px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    color: #244880;
    border-radius: 40px;
    height: 300px;
    @include lg {
        height: 450px;
    }
    > span {
        position: relative;
        &::before {
            content: "";
            background-color: #d9d9d9;
            width: calc(100% + 20px);
            transform: translateX(-10px);
            height: 6px;
            bottom: -30px;
            left: 0;
            position: absolute;
        }
    }
}
.mainTitle {
    @apply text-3xl;
    @include xxl {
        @apply text-4xl;
    }
}
